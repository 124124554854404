import React from "react"
import styled from "styled-components"

const square = size => `
  width: ${size};
  height: ${size};
`
const percentage = num => `${num * 100}%`

const randNo = () => Math.random()

const generateStars = (
  starsNo = 25,
  minSize = 2,
  maxSize = 14,
  clusterclassName = "stars-cluster-1",
  result = ""
) => {
  const currSize = Math.round(randNo() * (maxSize - minSize)) + minSize + "px"
  const currtOpacity = randNo() * 0.6 + 0.2
  const currtCSS =
    result +
    `
    & .${clusterclassName} .star-${starsNo} {
      ${square(currSize)}
      opacity: ${currtOpacity};
      top: ${percentage(randNo())};
      left: ${percentage(randNo())};
      animation-duration: ${randNo() * 2 + 3}s;
      animation-delay: ${randNo()}s;
    }
  `
  if (starsNo === 1) return currtCSS
  if (starsNo > 0)
    return generateStars(
      starsNo - 1,
      minSize,
      maxSize,
      clusterclassName,
      currtCSS
    )
}

const Wrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

  .stars-cluster {
    position: absolute;
    left: 1%;
    right: 1%;
    top: 1%;
    bottom: 1%;

    &.stars-cluster-1 {
      left: 15%;
      right: 30%;
      top: 20%;
      bottom: 20%;

      .star-7,
      .star-22,
      .star-30 {
        animation: shooting-star 10s ease-out 1s forwards;
      }
      .star-22 {
        animation-delay: 4s;
        animation-iteration-count: infinite;
      }
      .star-30 {
        animation-delay: 7s;
      }
      .star-20 {
        animation: shooting-star-2 8s ease-out 5s forwards infinite;
      }
    }

    &.stars-cluster-2 {
      right: 25%;
      animation: spining-stars 300s ease-in 2s forwards infinite;

      .star-11,
      .star-16 {
        animation: shooting-star 10s ease-out 8s forwards;
      }
      .star-16 {
        animation-delay: 1s;
      }
      .star-7 {
        animation-delay: 9s;
        animation-iteration-count: infinite;
      }
      .star-4 {
        animation: shooting-star-2 11s ease-out 6s forwards infinite;
      }
    }

    &.stars-cluster-3 {
      top: 50%;
    }
  }

  .star {
    position: absolute;
    animation-name: pulsate;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;

    &::before, &::after {
      content: "";
      position: absolute;
      background-color: #f3f2d6;
      display: block;
      left: 0;
      width: 60%;
      top: 0;
      bottom: 0;
      border-radius: 5%;
      transform: rotate(66.66deg) skewX(45deg);
    }

    &::after {
      transform: rotate(156.66deg) skew(45deg);
    }
  }

  /**
  * ----------------------------------------
  * star generator
  * ---------------------------------------
  */
  ${generateStars(40, 1, 3, "stars-cluster-1")}
  ${generateStars(40, 2, 4, "stars-cluster-2")}
  ${generateStars(15, 2, 5, "stars-cluster-3")}

  /**
  * ----------------------------------------
  * animation pulsate
  * ---------------------------------------
  */
  @keyframes pulsate {
    0%, 30%, 100% {
      transform: scale(.75);
      opacity: .2;
      box-shadow: 0 0 0 #fff, 0 0 0 #fff;
    }
    75%, 77% {
      transform: scale(1);
      opacity: .6;
      box-shadow: 0 0 2px #fff, 0 0 4px #fff;
    }
  }

  /**
  * ----------------------------------------
  * animation shooting-star
  * ---------------------------------------
  */
  @keyframes shooting-star {
    0% {
      transform: scale(1);
      transform: translate(0,0);
      opacity: 1;
    }
    20%,100% {
      transform: scale(0);
      transform: translate(400px,200px);
      opacity: 0;
    }
  }

  /**
  * ----------------------------------------
  * animation shooting-star-2
  * ---------------------------------------
  */
  @keyframes shooting-star-2 {
    0% {
      transform: scale(1);
      transform: translate(0,0);
      opacity: 1;
    }
    10%,100% {
      transform: scale(.7);
      transform: translate(600px,150px);
      opacity: .3;
    }
  }

  /**
  * ----------------------------------------
  * animation spining-stars
  * ---------------------------------------
  */
 @keyframes spining-stars {
    0% {
      transform: rotate( 0deg );
    }
    100% {
      transform: rotate( 360deg );
    }
  }
`

const Particles = () => (
  <Wrapper>
    <div className="stars-cluster stars-cluster-1 layer" data-depth="0.30">
      {[...Array(40)].map((e, i) => {
        const className = "star star-" + (i + 1)
        return <div className={className} key={i}></div>
      })}
    </div>
    <div className="stars-cluster stars-cluster-2 layer" data-depth="0.50">
      {[...Array(40)].map((e, i) => {
        const className = "star star-" + (i + 1)
        return <div className={className} key={i}></div>
      })}
    </div>
    <div className="stars-cluster stars-cluster-3 layer" data-depth="0.90">
      {[...Array(15)].map((e, i) => {
        const className = "star star-" + (i + 1)
        return <div className={className} key={i}></div>
      })}
    </div>
  </Wrapper>
)

export default Particles
