import React from "react"
import styled from "styled-components"
import { mobile } from "~utilities/mediaQuery"

import Particles from "./Particles.js"
import gradient from "~assets/images/background-back-gradient.png"
import radial from "~assets/images/background-gradient-radial.png"

const Background = (props) => {
  return (
    <Wrapper {...props}>
      <Gradient />
      <Particles />
      <Radial className="radial" />
    </Wrapper>
  )
}

export default Background

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-black);
  z-index: -2;
`

const Radial = styled.div`
  position: absolute;
  height: 100%;
  background-image: url(${radial});
  width: 80%;
  right: -15%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;

  ${mobile} {
    width: 100%;
    right: 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  height: 100%;
  background-image: url(${gradient});
  width: 100%;
  bottom: 0;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
`
